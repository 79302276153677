@import "~antd/dist/antd.css";

.App {
	height: 100vh;
	overflow: hidden;
}

.Content {
	margin-top: 0px;
	height: 100vh;
}

.Header {
	width: 100%;
	height: 64;
	padding: 0;
}

.Fill {
	width: 100%;
	height: 100%;
}

.zeroMargin {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	height: 20px;
}

.Card {
	background-color: white;
	padding: 12px;
	border-radius: 6px;
	margin-bottom: 24px;
	//box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 0.2);
}

.scroll {
	overflow: auto;
}
.Header {
	width: 100%;
	height: 64;
	padding: 0;
}

.Fill {
	width: 100%;
	height: 100%;
}

.zeroMargin {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	height: 20px;
}

.Name {
	font-size: 28px;
	font-weight: 500;
	margin-left: 12px;
}

.Key {
	font-size: 14px;
	font-weight: 500;
	margin-top: 0px;
	margin-bottom: 6px;
}

.Value {
	font-size: 14px;
	font-weight: 300;
	margin-top: 0px;
	margin-bottom: 6px;
}

.SectionTitle {
	font-size: 20px;
	margin-top: 0px;
	margin-bottom: 12px;
	font-weight: 500;
}

.WarningBanner {
	background-color: rgb(9, 129, 209);
	height: 30px;
	width: 100%;
}
